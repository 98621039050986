import React from "react";
import "./reset.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";

import Loading from "./Components/loading";
import { Router } from "./router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop /> 
      <Loading />
      <Router />
    </BrowserRouter>
  </React.StrictMode>
);
