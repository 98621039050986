import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Loading from "./Components/loading.js";

const Home = lazy(() => import("./Pages/Home/Home"));
const Buzios = lazy(() => import("./Pages/Buzios/Buzios"));
const Error404 = lazy(() => import("./Pages/Error404/Error404"));
const LastOffer = lazy(() => import("./Pages/LastOffer/index"));
const LastOfferThanks = lazy(() => import("./Pages/LastOfferThanks/index"));

export function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>        
        <Route path="/" element={<Home />} />    
        <Route path="/*" element={<Error404 />} />      

        <Route path="/FormaçãoDeBúzios" element={<Buzios />} />
        <Route path="/FormacaodeBuzios" element={<Buzios />} />  
        <Route path="/Buzios" element={<Buzios />} />      
        <Route path="/Búzios" element={<Buzios />} /> 

        <Route path="/Oferecer" element={<LastOffer />} />
        <Route path="/Obrigado" element={<LastOfferThanks />} />

      </Routes>
    </Suspense>
  );
}