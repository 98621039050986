import styled from "styled-components";
import { useState, useEffect } from "react";

import { TailSpin } from "react-loader-spinner";

export default function Loading() {
    
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const Container = styled.div`
    .LoadingIcon {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      background-color: #BFD9FF;
      position: fixed;
      z-index: 999;
    }
  `;

  return (
    <Container>
      {loading ? (
        <div className="LoadingIcon">
          <TailSpin
            color="#274CD0"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
}